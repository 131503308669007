<template>
  <div>
    <b-card class="p-3">
      <notification
        v-show="!showSettings"
        @open-settings="showSettings = true"
      />
      <notification-settings
        v-show="showSettings"
        @hide-settings="showSettings = false"
      />
    </b-card>
  </div>
</template>

<script>
import Notification from "./Notification";
import NotificationSettings from "./NotificationSettings";

export default {
  name: "NotificationsMobile",
  components: { Notification, NotificationSettings },
  data() {
    return {
      showSettings: false
    };
  }
};
</script>
